 <template>
  <div class="w-100">
    <basic-subheader>
      <template v-slot:actions>
        <b-row>
          <b-col>
            <div class="d-flex justify-content-end">
              <b-button class="btn mr-4" type="button" @click="returnPage">
                <span class="svg-icon">
                  <inline-svg
                    src="/media/svg/icons/Neolex/Basic/arrow-left.svg"
                  />
                </span>
                Hủy
              </b-button>
              <b-button
                class="btn btn-success"
                type="button"
                @click="submit"
                :disabled="loading"
              >
                <span class="svg-icon">
                  <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
                </span>
                Lưu
              </b-button>
            </div>
          </b-col>
        </b-row>
      </template>
    </basic-subheader>
    <b-overlay>
      <FormCourses
        ref="form"
        @submit="createCourses"
        :coachesList="this.coachesList"
      />
    </b-overlay>
  </div>
</template>
<script>
export default {
  name: 'CoursesCreateDetail',
  components: {
    FormCourses: () => import('./components/CoursesCreateDetail.vue'),
  },
  props: {
    coachesList: {
      type: Object,
      default: () => {},
    },
    courseData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      detail: null,
    };
  },
  created() {},

  methods: {
    returnPage() {
      this.$router.go(-1);
    },
    async submit() {
      if (this.loading) return;

      this.loading = true; // Set loading state to true
      try {
        await this.$refs.form.createCourses().then(() => {});
      } catch (error) {
        console.error('Error:', error);
      } finally {
        this.loading = false;
      }
    },
    handleValidate() {
      this.$refs.form.handleValidate();
    },
  },
};
</script>
<style lang=""></style>
